import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4de5109f&scoped=true"
import script from "./dashboard.js?vue&type=script&lang=js&external"
export * from "./dashboard.js?vue&type=script&lang=js&external"
import style0 from "./dashboard.scss?vue&type=style&index=0&id=4de5109f&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de5109f",
  null
  
)

export default component.exports